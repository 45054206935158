import dynamic, { DynamicOptions } from 'next/dynamic';
import path from 'path';
import { SectionsKey } from '~/constants/enum';
import { checkFileExists, getTheme } from './theme';

export const getPageStruct = (themeName = getTheme(), fileName = '') => {
    if (!fileName) return null;
    try {
        const PageStruct = require(`~/theme/${themeName}/structure/${fileName}.struct`);
        return PageStruct.default;
    } catch (error) {
        const PageStruct = require(`~/theme/base/structure/${fileName}.struct`);
        return PageStruct.default;
    }
};

export const renderSectionNew = (item: any, options?: DynamicOptions) => {
    switch (item.id) {
        //struct: product-detail-two
        case SectionsKey.ProductDetailTwo:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductDetailTwo`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/ProductDetailTwo`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.Related:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Related`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/Related`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.ProductList:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductList`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/ProductList`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.ProductTags:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/ProductTags`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/ProductTags`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.Cart:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/Cart`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/Cart`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.NotFound:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/NotFound`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/NotFound`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.CollectionsContent:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/CollectionsContent`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/CollectionsContent`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.TabReviews:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/TabReviews`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/TabReviews`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.RecentlyView:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/RecentlyView`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/RecentlyView`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        case SectionsKey.BlogList:
            return dynamic(
                () =>
                    import(`~/theme/${getTheme()}/sections/BlogList`)
                        .then((mod) => mod)
                        .catch((error) => {
                            return import(`~/sections/BlogList`).then((mod) => mod);
                        }),
                {
                    ...options
                }
            );
        default:
            return null;
    }
};

export const renderSectionByKey = (
    key: SectionsKey | { id: SectionsKey },
    dynamicProps: DynamicOptions<any> = {} as DynamicOptions<any>
) => {
    const _key = typeof key === 'string' ? key : key?.id;
    return dynamic(
        () =>
            import(`~/theme/${getTheme()}/sections/${_key}`)
                .then((mod) => mod)
                .catch((error) => {
                    return import(`~/sections/${_key}`).then((mod) => mod);
                }),
        {
            ...dynamicProps
        }
    );
};
